import React, { useRef, useEffect } from "react";
import "./FullscreenVideoPlayer.css";

const FullscreenVideoPlayer = ({ src, onTimeUpdate }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        videoRef.current.pause();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleDoubleClick = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen();
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current && onTimeUpdate) {
      onTimeUpdate(videoRef.current.currentTime);
    }
  };

  return (
    <video
      ref={videoRef}
      src={src}
      className="video-player"
      autoPlay
      muted
      onClick={handleVideoClick}
      onDoubleClick={handleDoubleClick}
      onTimeUpdate={handleTimeUpdate}
    />
  );
};

export default FullscreenVideoPlayer;
