import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Pause from './assets/pause.svg'; 
import Play from './assets/play.svg'; 
import SpotifyIcon from './assets/Spotify_Icon_RGB_White.png'; 

const track = {
    name: "",
    album: {
        images: [
            { url: "" }
        ]
    },
    artists: [
        { name: "" }
    ]
}

const WebPlayback = ({ token, initSongUI, resetPrompts,  }) => {

    const [is_paused, setPaused] = useState(false);
    const [is_active, setActive] = useState(false);
    const [is_visible, setVisibility ] = useState(false);
    const [player, setPlayer] = useState(undefined);
    const [current_track, setTrack] = useState(track);
    // const [deviceId, setDeviceId] = useState('');


    const deviceIdRef = useRef('');

    function setDeviceId(id) {
    console.log(`Setting deviceId: ${id}`);
    deviceIdRef.current = id;
    }

    

    function seeTrackOnSpotify() {
        window.location.href = `https://open.spotify.com/track/${current_track.id}`;
    }
    

    async function playSong(songId) {
        // Replace this hardcoded song ID with a dynamic one in your final implementation
        // let songId = "6PYazhU8WHEsQLiBr4xUgt";
        let songURI = `spotify:track:${songId}`;
    
        try {
            await axios.put(
                `https://api.spotify.com/v1/me/player/play?device_id=${deviceIdRef.current}`,
                { uris: [songURI],    "position_ms": 0 },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            resetPrompts();
            initSongUI();
            console.log(`Playing song with URI: ${songURI}`);
        } catch (error) {
            console.error("Error playing specific song:", error);
        }
    }
    
    




    useEffect(() => {

        const script = document.createElement("script");
        script.src = "https://sdk.scdn.co/spotify-player.js";
        script.async = true;




        document.body.appendChild(script);

        window.onSpotifyWebPlaybackSDKReady = () => {

            const player = new window.Spotify.Player({
                name: 'ANNA Web Experience',
                getOAuthToken: cb => { cb(token); },
                volume: 0
            });


            const fadeInVolume = (player, targetVolume, duration) => {
                let currentVolume = 0;
                const step = targetVolume / (duration / 100);
                const interval = setInterval(() => {
                  currentVolume += step;
                  if (currentVolume >= targetVolume) {
                    currentVolume = targetVolume;
                    clearInterval(interval);
                  }
                  player.setVolume(currentVolume);
                }, 100);
              };
              

            setPlayer(player);

            const handleSongClicked = async (e) => {
                await playSong(e.detail);
                player.togglePlay().then(() => {
                  fadeInVolume(player, 0.5, 16000); 
                });
              };
              

            setTimeout(() => {
                handleSongClicked({detail:'6PYazhU8WHEsQLiBr4xUgt'});
            }, (4000));
            

      

            player.addListener('ready', ({ device_id }) => {
                console.log('Ready with Device ID', device_id);
                setDeviceId(device_id);
                // transferPlayback(device_id);
            });

            player.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id);
            });

            document.addEventListener("song-clicked", handleSongClicked);
            player.addListener('player_state_changed', (state => {

                if (!state) {
                    return;
                }

                setTrack(state.track_window.current_track);
                setPaused(state.paused);

                player.getCurrentState().then(state => {
                    (!state) ? setActive(false) : setActive(true)
                    setTimeout(() => {
                        (!state) ? setVisibility(false) : setVisibility(true)
                    },100)
                });

            }));

            player.connect();

        };
    }, []);


    if (!is_active) {
        return (
            <>
                {/* <div className="container ui-overlay ">
                    <div className="main-wrapper">
                        <b> Instance not active. Transfer your playback using your Spotify app </b>
                    </div>
                </div> */}
            </>)
    } else {
        return (
            <>

            {/* <div className={`container  ui-overlay`}>
            <div className={`main-wrapper align-centre animation-text-wipe ${is_visible ? 'animate-in' : ''}`}>
            <a onClick={() => {seeTrackOnSpotify()}} href={`https://open.spotify.com/track/${current_track.id}`} style={{pointerEvents:'all'}} >
            <img onClick={() => {seeTrackOnSpotify()}} className='spotify-icon' src={SpotifyIcon} />
             </a>
            <a onClick={() => {seeTrackOnSpotify()}} href={`https://open.spotify.com/track/${current_track.id}`} style={{pointerEvents:'all'}} >
                <img src={current_track.album.images[0].url} className="now-playing__cover" alt="" />
            </a>
                <div className="now-playing__side align-centre">
                    <div className="now-playing__name">{current_track.name}</div>
                    <img className='control pointer-all' onClick={() => {
                        player.togglePlay();
                        console.log('toggle play')
                        }} src={is_paused ? Play : Pause}/>

                </div>
            </div>
        </div> */}
            </>


        );
    }
}

export default WebPlayback
