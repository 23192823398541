import React, { useEffect, useRef } from 'react';
const web_url = process.env.REACT_APP_REDIRECT_ID;

const ImageGenerator = ({promptResponses}) => {


  useEffect(() => {
    drawOnCanvas();
  }, []);

  const canvasRef = useRef(null);

  const drawOnCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'anonymous'; // For CORS issues when loading images from external sources
    image.src = './ANNA_SINGLES_01_RECEIVING_WITHOUT_TEXT.jpg'; // Provide the URL or local path of your image

    image.onload = () => {
      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Set up the text properties
      ctx.font = '50px Louize';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.letterSpacing = '10px';

      // Set up the drop shadow
      //   ctx.shadowColor = 'black';
      //   ctx.shadowBlur = 5;
      //   ctx.shadowOffsetX = 2;
      //   ctx.shadowOffsetY = 2;

      // Define your words

      // Calculate the vertical spacing between words
      
      const words = promptResponses.length > 0 ? promptResponses : ['Intentions']
      const spacing = canvas.height / (words.length + 1);

      // Draw each word on the canvas
      for (let i = 0; i < words.length; i++) {
        ctx.fillText(words[i].toUpperCase(), canvas.width / 2, spacing * (i + 1));
      }
    };
  };

  const shareNative = async (imageDataUrl, text, websiteUrl) => {
    if (navigator.share) {
      try {
        // Convert the Data URL to a Blob
        const byteString = atob(imageDataUrl.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          uint8Array[i] = byteString.charCodeAt(i);
        }
        const imageBlob = new Blob([arrayBuffer], { type: 'image/png' });

          // Create a File object from the Blob
      const imageFile = new File([imageBlob], 'shared-image.png', { type: 'image/png' });

        // Share the image, text, and website URL using the Web Share API
        await navigator.share({
          text: `${text} ${websiteUrl}`,
          files: [imageFile],
        });

        
  
        console.log('Content shared using the native share dialog');
      } catch (error) {
        console.error('Error while sharing using the native share dialog:', error);
      }
    } else {
      console.error('Web Share API not supported in this browser');
    }
  };
  

  const isShareSupported = navigator.share !== undefined;



  const getImageUrl = async () => {
    const canvas = canvasRef.current;
    return canvas.toDataURL('image/jpeg')
  };

  const shareImage = async () => {
    const imageUrl = await getImageUrl();
    shareNative(imageUrl, 'What are your intentions?', web_url);
  };

  const downloadImage = async () => {
    const imageUrl = await getImageUrl();
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'generated-image.png';
    link.click();
  };


  return (
    <div className='share-screen'>
      <div className='share'>
        <canvas className='share-canvas' ref={canvasRef} width={500} height={500}></canvas>
        {isShareSupported ? (<>
          <div className='btn-spotify align-centre' style={{ marginTop: '40px' }} onClick={downloadImage}>Download Image</div>
          <div className='btn-spotify align-centre' style={{ marginTop: '20px' }} onClick={shareImage}>Share Image</div>
          </>
        ) : (
          <div className='btn-spotify align-centre' style={{ marginTop: '40px' }} onClick={downloadImage}>Download Image</div>
        )}
      </div>
    </div>
  );
};

export default ImageGenerator;
