import React from 'react';
import SpotifyLogin from './spotify/login';

function Login() {
  const handleLogin = () => {
    SpotifyLogin.logInWithSpotify();
  }

  return (
    <div className="btn-spotify" onClick={handleLogin}>
      Login with Spotify
    </div>
  );
}

export default Login;
