import React, { useState, useEffect } from 'react';

const promptMessages = [
  'How do you feel?',
  'What do you see?',
  'What is your intention?'
]

const Prompt = ({ reset, savePromptResponse, promptNumber, renderPrompt }) => {
  const [inputValue, setInputValue] = useState('');


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      savePromptResponse(inputValue);
      // setState(false);
    }
  };


  return (
    <div className={`${renderPrompt ? `active` : `inactive`} input-container`} style={{ marginTop: '20px', textAlign: 'center' }}>

      <div className='mask'>
        <div className='backdrop'></div>
        <div className="instructions" style={{ fontSize: '18px', fontWeight: 'bold' }}>
          <p className='single-word'>In a single word</p>
          <p className='intention'>{promptMessages[promptNumber]}</p>
        </div>
        <input
          type="text"
          className="custom-input"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder='Enter Word'

        />
        <div className='underline' />

        <div className={`btn-spotify align-centre ${inputValue === '' ? 'disabled' : '' }`} style={{ marginTop: '20px',pointerEvents:'all'}} onClick={() => {
          if(inputValue !== ''){
            savePromptResponse(inputValue)
          }
          }}>Enter</div>
      </div>
    </div>
  );
};

export default Prompt;
