import React, { useState, useEffect } from 'react';
import WebPlayback from './WebPlayback';
import Login from './Login';
import './App.css';
import Logo from './assets/anna-logo.png';
import Share from './assets/share.svg';
import Close from './assets/close.svg';
import SpotifyLogin from './spotify/login';
import ImageGenerator from './ImageGenerator';
import Prompt from './Prompt';
import FullscreenVideoPlayer from './FullScreenVideoPlayer';

function App() {
  const [token, setToken] = useState('');
  const [appIntro, setAppIntro] = useState(true);
  const [shareScreen, setShareScreen] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [renderPrompt, setRenderPrompt] = useState(false);
  const [promptResponses, setPromptResponses] = useState([]);
  const [promptNumber, setPromptNumber] = useState(0);
  const [resetPrompt, setResetPrompt] = useState(false);
  const [videoTime, setVideoTime] = useState(0);



  const handleTimeUpdate = (currentTime) => {
    setVideoTime(currentTime);
  };

  const savePromptResponse = (response) => {
    setPromptResponses([...promptResponses, response]);

    hidePrompt();

    setTimeout(() => {
      setPromptNumber(promptNumber + 1);
    }, 2000);

  };

  const resetPrompts = () => {
    setPromptResponses([]);
    setPromptNumber(0);
    setResetPrompt((prevReset) => !prevReset);

  };

  let displayTimeout;
  const displayPrompt = () => {
    setShowPrompt(true);
    displayTimeout = setTimeout(() => {
      setRenderPrompt(true);
    }, 2000);
  }

  let hideTimeout;
  const hidePrompt = () => {
    setRenderPrompt(false);
    hideTimeout = setTimeout(() => {
      setShowPrompt(false);
    }, 2000);
  }

  let promptTimeout;
  const initSongUI = async (e) => {

    clearTimeout(promptTimeout);
    clearTimeout(displayTimeout);
    clearTimeout(hideTimeout);
    clearTimeout(effectTimeout);
    hidePrompt();
    setTimeout(() => {
      promptTimeout = displayPrompt();

    }, 8000);
  };

  let effectTimeout;
  useEffect(() => {
    if (promptNumber > 0 && promptNumber < 3) {
      hidePrompt();

      effectTimeout = setTimeout(() => {
        displayPrompt();
      }, promptNumber === 0 ? 8000 : 6000);
    } else {
      hidePrompt();
    }
  }, [promptNumber]);

  useEffect(() => {
    async function getToken() {
      const loginToken = SpotifyLogin.getToken();
      if (!loginToken) {

      } else {
        setToken(loginToken);
      }
    }

    getToken();
  }, []);

  useEffect(() => {


    if (token !== '') {
      setTimeout(() => {
        setAppIntro(false);
      }, 4000);


    }


  }, [token]);

  function handleLogout() {
    setToken(''); // Clear the access token
    window.location.href = 'https://www.spotify.com/logout/';
  }

  return (
    <>
      <div className={`App ${!appIntro ? 'inactive' : ''} `}>
        <header className="App-header">
          <div className='login-contents'>
            {/* <img className='logo' src={Logo} /> */}
            <div className="anna-title">Intentions</div>
            {token === '' ? (<Login />) : (<></>)}
          </div>


        </header>

      </div>
      <div onClick={() => { setShareScreen(!shareScreen) }} className={`share-button ${appIntro ? 'inactive' : ''}`}>
        <img style={{ width: '25px' }} src={shareScreen ? Close : Share} />
      </div>


      {showPrompt && (
        <Prompt renderPrompt={renderPrompt} promptNumber={promptNumber} reset={resetPrompt} savePromptResponse={savePromptResponse} />
      )}


      {shareScreen === true ? (
        <ImageGenerator promptResponses={promptResponses} />
      ) : (<></>)}

      {token === '' ? (<></>) : (<>
        <div >
          <WebPlayback initSongUI={initSongUI} resetPrompts={resetPrompts} token={token} />


        </div>


      </>)}

      <div className="video-container">
        <FullscreenVideoPlayer onTimeUpdate={handleTimeUpdate} src='https://anna-intentions.s3.eu-west-1.amazonaws.com/ANNA_VIS_03_ITS_ALL_YOU_v001_LYRICS_V01_230118_1555_1080.mp4' />
      </div>
    </>
  );
}

export default App;
